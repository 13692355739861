
import { ref, watch, defineComponent, onMounted } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";

export default defineComponent({
  name: "ForwarderJobTrafficTypeSelect",
  props: {
    forwarder_job_traffic_types_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:forwarder_job_traffic_types_id", "ChangeForwarderJobTrafficType"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const code = ref("");
    const inputForwarderJobTrafficType = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isSalesJobTrafficTypeIdRequired = (value) => {
      if (props.required && !value) {
        return t("salestraffictypeid");
      }
      return true;
    };

    const {
      errorMessage,
      value: element_id,
      meta,
    } = useField("forwarder_job_traffic_types_id", isSalesJobTrafficTypeIdRequired);

    const getForwarderJobTrafficType = () => {
      ApiService.get("/api/forwarder/job/traffic_type").then(({ data }) => {
        inputForwarderJobTrafficType.value.list = data.forwarder_job_traffic_types;
        inputForwarderJobTrafficType.value.options =
          data.forwarder_job_traffic_types;
      });
    };
    const selectSalesJobTrafficType = (query) => {
      if (query !== "") {
        inputForwarderJobTrafficType.value.loading = true;
        setTimeout(() => {
          inputForwarderJobTrafficType.value.loading = false;
          inputForwarderJobTrafficType.value.options =
            inputForwarderJobTrafficType.value.list.filter(
              (item: Record<string, any>) => {
                return (
                  item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
              }
            );
          if (!inputForwarderJobTrafficType.value.options.length) {
            ApiService.query(`/api/forwarder/job/traffic_type`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              inputForwarderJobTrafficType.value.list =
                data.forwarder_job_traffic_types;
              inputForwarderJobTrafficType.value.options =
                data.forwarder_job_traffic_types;
            });
          }
        }, 200);
      } else {
        inputForwarderJobTrafficType.value.options = [];
      }
    };

    watch(
      () => props.forwarder_job_traffic_types_id,
      (first) => {
        element_id.value = first;
        const temp = inputForwarderJobTrafficType.value.options.filter(
          (item) => {
            if (typeof (first) === 'object' && first !== null) {
              return first?.includes(item.id);
            }
            else {
              return item.id === first;
            }
          }
        );
        if (!props.multiselect && temp.length) {
          code.value = temp[0]["name"];
          emit("ChangeForwarderJobTrafficType", code.value);
        }
      },
      { deep: true }
    );

    setTimeout(() => {
      getForwarderJobTrafficType();
    });

    onMounted(() => {
      element_id.value = props.forwarder_job_traffic_types_id;
    });

    return {
      element_id,
      inputForwarderJobTrafficType,
      errorMessage,
      meta,
      getForwarderJobTrafficType,
      selectSalesJobTrafficType,
    };
  },
});
