
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useField } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
  name: "DocksSelect",
  props: {
    dock_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:dock_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const inputDock = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isDockRequired = (value) => {
      if (props.required && !value) {
        return t("rdockid");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "dock",
      isDockRequired
    );

    const getPort = () => {
      ApiService.get("/api/docks/lists").then(({ data }) => {
        inputDock.value.list = data.docks;
        inputDock.value.options = data.docks;
      });
    };
    const selectDock = (query) => {
      if (query !== "") {
        inputDock.value.loading = true;
        setTimeout(() => {
          inputDock.value.loading = false;
          inputDock.value.options = inputDock.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputDock.value.options.length) {
            ApiService.query(`/api/docks/lists`, {
              params: { per_page: 12, name: query },
            }).then(({ data }) => {
              inputDock.value.list = data.docks;
              inputDock.value.options = data.docks;
            });
          }
        }, 200);
      } else {
        inputDock.value.options = inputDock.value.list;
      }
    };

    watch(
      () => props.dock_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      getPort();
      setTimeout(function () {
        element_id.value = props.dock_id;
      }, 500);
    });

    return {
      element_id,
      inputDock,
      errorMessage,
      getPort,
      selectDock,
    };
  },
};
