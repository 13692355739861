
import CardBasicNew from "@/components/cards/CardBasicNew.vue";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, onMounted, onUpdated, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ForwarderJobTrafficTypeSelect from "@/components/catalogs-select/ForwarderJobTrafficTypeSelect.vue";
import GeneralUseSelect from "@/components/catalogs-select/GeneralUseSelect.vue";
import ShipsSelect from "@/components/catalogs-select/ShipsSelect.vue";
import PortsSelect from "@/components/catalogs-select/PortsSelect.vue";
import DocksSelect from "@/components/catalogs-select/DocksSelect.vue";
import moment from "moment";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}
export default {
  name: "MaritimeVoyage",
  components: {
    CardBasicNew,
    TableBasicSelect,
    ForwarderJobTrafficTypeSelect,
    ShipsSelect,
    PortsSelect,
    DocksSelect,
    GeneralUseSelect
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const search = ref("");
    const load = ref(false);
    const header = ref([
      { column: t("titraffic_type"), key: "titraffic_type" },
      {
        column: t("ivessel"),
        key: "ivessel",
      },
      { column: t("icontain_origin"), key: "icontain_origin" },
      { column: t("icontain_destination"), key: "icontain_destination" },
      { column: t("idescription"), key: "idescription" },
      { column: t("tmaritimevoyage"), key: "tmaritimevoyage" },
    ]);
    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };
    const canCreate = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "forwarder maritime voyage: create"
        ) !== -1
      );
    });
    const canSeeDetails = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "forwarder maritime voyage: show"
        ) !== -1
      );
    });
    const canEdit = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "forwarder maritime voyage: update"
        ) !== -1
      );
    });
    const canDelete = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "forwarder maritime voyage: delete"
        ) !== -1
      );
    });

    const showDelete = ref(false);
    const deleteId = computed(() => store.getters.DeleteId);
    const dataList = ref([]);
    const filtered = ref([]);
    const viewType = ref(user.value.user_preferences.view_type_items);
    const tableButtons = ref([]);
    const originalData = ref([]);
    const forwarder_job_traffic_type_id = ref([]);
    const vessel_id = ref([]);
    const origin_id = ref([]);
    const destination_id = ref([]);
    const dock_id = ref([]);
    const voyage_number = ref("");
    const estimated_departure_date = ref([]);
    const real_departure_date = ref([]);
    const estimated_arrival_date = ref([]);
    const real_arrival_date = ref([]);
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.value.user_preferences.items_per_page,
      total: 0,
      last_page: 1,
    });

    const formatDate = (value) => {
      return moment(value).format("DD/MM/YYYY");
    };

    const deleteDialog = (value) => {
      store.commit("setDeleteId", value);
      showDelete.value = true;
    };

    const deleteElement = () => {
      ApiService.delete(
        `/api/forwarder/voyage/maritime/${deleteId.value}`
      ).then(({ data }) => {
        store.commit("setDeleteId", undefined);
        showDelete.value = false;
        serverSearch();
      });
    };

    const updateData = (items: any) => {
      originalData.value = items;
      dataList.value = createFieldItems(items);
      filtered.value =
        viewType.value === "card" ? dataList.value : createTableItems(items);
      tableButtons.value = [
        {
          name: "Editar" as never,
          type: "EDIT" as never,
          color: "primary" as never,
        } as never,
      ];
    };

    const currentPageChange = (val) => {
      pagination.value.page = val;
      serverSearch();
    };

    const setItemsPerPage = (event) => {
      pagination.value.rowsPerPage =
        typeof event === "object" ? parseInt(event.target.value) : event;
      serverSearch();
    };

    const onSearch = () => {
      filtered.value = dataList.value.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    const serverSearch = () => {
      store.commit("setLoadingStatus", true);
      ApiService.query(`/api/forwarder/voyage/maritime`, {
        params: {
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
          forwarder_job_traffic_type_id: forwarder_job_traffic_type_id.value,
          vessel_id: vessel_id.value,
          origin_id: origin_id.value,
          destination_id: destination_id.value,
          dock_id: dock_id.value,
          voyage_number: voyage_number.value,
          estimated_departure_date_min: estimated_departure_date.value.length
            ? estimated_departure_date.value[0]
            : "",
          estimated_departure_date_max: estimated_departure_date.value.length
            ? estimated_departure_date.value[1]
            : "",
          real_departure_date_min: real_departure_date.value.length
            ? real_departure_date.value[0]
            : "",
          real_departure_date_max: real_departure_date.value.length
            ? real_departure_date.value[1]
            : "",
          estimated_arrival_date_min: estimated_arrival_date.value.length
            ? estimated_arrival_date.value[0]
            : "",
          estimated_arrival_date_max: estimated_arrival_date.value.length
            ? estimated_arrival_date.value[1]
            : "",
          real_arrival_date_min: real_arrival_date.value.length
            ? real_arrival_date.value[0]
            : "",
          real_arrival_date_max: real_arrival_date.value.length
            ? real_arrival_date.value[1]
            : "",
        },
      }).then(({ data }) => {
        updateData(data.forwarder_maritime_voyages);
        pagination.value.total = data.pagination.total;
        pagination.value.last_page = data.pagination.last_page;
        store.commit("setLoadingStatus", false);
      });
    };

    const createFieldItems = (data) => {
      const add_fields = data.map(function (element) {
        element.items = {
          id: element.id,
          titraffic_type: element.forwarder_job_traffic_type_id,
          ivessel: element.vessel_id,
          icontain_origin: element.origin_id,
          icontain_destination: element.destination_id,
          tmaritimevoyage: element.voyage_number,
        };
        return element;
      });
      return add_fields;
    };

    const createElement = () => {
      router.push({ name: "maritimeVoyage" });
    };

    const createTableItems = (data) => {
      const result = [] as any;
      data.map(function (element) {
        result.push({
          id: element.id,
          titraffic_type: element.forwarder_job_traffic_type.name,
          ivessel: element.vessel.name,
          icontain_origin: element.origin.name,
          icontain_destination: element.destination.name,
          idescription: element.description,
          tmaritimevoyage: element.voyage_number,
        });
      });
      return result;
    };

    watch(
      () => viewType.value,
      (first) => {
        if (first) {
          filtered.value =
            first === "card"
              ? createFieldItems(originalData.value)
              : createTableItems(originalData.value);
          pagination.value.total = filtered.value.length;
          pagination.value.last_page = Math.floor(filtered.value.length / 12);
        }
      }
    );

    const actionsButton = (param1, param2, param3) => {
      if (param2 === "EDIT") {
        router.push({ name: "maritimeVoyageEdit", params: { id: param1 } });
      } else if (param2 === "REMOVE") {
        deleteDialog(param3);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("", [
        { route: "/forwarder/maritime_voyages", label: "timaritimetrips" },
      ]);
      serverSearch();
    });

    onUpdated(() => {
      //console.clear();
    });

    return {
      load,
      header,
      search,
      permissions,
      canCreate,
      canSeeDetails,
      canEdit,
      canDelete,
      showDelete,
      deleteId,
      dataList,
      filtered,
      forwarder_job_traffic_type_id,
      vessel_id,
      origin_id,
      destination_id,
      dock_id,
      voyage_number,
      estimated_departure_date,
      real_departure_date,
      estimated_arrival_date,
      real_arrival_date,
      viewType,
      tableButtons,
      originalData,
      pagination,
      formatDate,
      deleteDialog,
      deleteElement,
      currentPageChange,
      setItemsPerPage,
      onSearch,
      serverSearch,
      createElement,
      createTableItems,
      actionsButton,
      user,
    };
  },
};
